@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

html, .app {
    background-color: #a39f82;
    background-image: URL('./assets/exclusive-paper.png');
    background-repeat: repeat;
}

body {
    margin-top: 70px;
}

.pageTitle {
    font-family: 'Alfa Slab One', cursive;
}

.Card, .card {
    Width: 200px;
    background-color: #D7D7D7;
    box-shadow: 5px 5px 15px 2px rgba(0,0,0,0.69);
}

.search {
    justify-content: right;
}

.newBookForm {
    max-width: 500px;
    padding-left: 50px;
    padding-right: 50px;
}

#newBookBtn {
    border-radius: 8px;
    border: 2px solid black;
    background-color: #D7D7D7;
}

#newBookBtn:hover {
    border: 2px solid black;
    background-color: #206d08;
}

.btn {
    border-radius: 8px;
    border: 2px solid #D7D7D7;
    background-color: black;
}

#authorpanel, #chapters {
    margin-top: 15px;
}

#authorpanel a, #chapters a, #editCoverBtn {
    width: 100%;
    min-height: 35px;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    border: 2px solid #D7D7D7;
    background-color: black;
    color: #d7d7d7;
}

#editchapterbtn {
    display: none;
    padding: 0 15px;
    margin-left: 15px;
    width: 125px;
    min-height: 35px;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    border: 2px solid #D7D7D7;
    background-color: black;
    color: #d7d7d7;
}

#authorpanel a:hover, #chapters a:hover, #editchapterbtn:hover, #editCoverBtn:hover {
    background-color: #d7d7d7;
    border: 2px solid black;
    color: black;
}

#content {
    background-color: #D7D7D7;
    background-image: URL('./assets/natural-paper.png');
    border: 2px solid black;
    border-radius: 8px;
    padding: 10px;
    min-height: 85vh;
}

.container {
    margin-left: 15px;
    margin-right: 15px;
}

.row {
    width: 90vw;
}

#aboutContent {
    margin: 0 auto;
    background-color: #D7D7D7;
    background-image: URL('./assets/natural-paper.png');
    border: 2px solid black;
    border-radius: 8px;
    padding: 10px;
    height: 85vh;
    width: 60%;
}

#increasefontbtn, #decreasefontbtn {
    width: 30px;
    font-size: large;
    min-height: 35px;
    border-radius: 16px;
    display: inline-block;
    text-align: center;
    border: 2px solid #D7D7D7;
    background-color: black;
    color: #d7d7d7;
}

#increasefontbtn:hover, #decreasefontbtn:hover {
    background-color: #d7d7d7;
    border: 2px solid black;
    color: black;
}

/* #fontcontrols {
    float: right;
} */

#chapterbar {
    display: inline-block;
}